import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Http, Response } from "@angular/http";
import { Subscription } from 'rxjs';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { doAnimate } from 'src/app/shared/app.helpers';
import { TourDateService } from '../../services/tour-date.service';
import { TourDate } from '../../models/TourDate';

declare var $: any;

@Component({
  selector: 'app-tour-date',
  templateUrl: './tour-date.component.html',
  styleUrls: ['./tour-date.component.scss'],
  providers: [TourDateService]
})
export class TourDateComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  constructor(private tourdateService: TourDateService, private http: Http, private router: Router) { }
  eventlistdata: any = {
    allList: [],
    conventionList: [],
    nationalList: [],
    regionalList: []
  };
  tourDate: TourDate;
  eventseasons: any = [];
  eventstates: any = [];
  selectedseason: any;
  eventmonthrange: any = [];
  EventFilters = {
    SeasonName: "",
    SeasonId: 0,
    EventType: "All",
    StateId: 0,
    StateName: "",
    Months: 0,
    MonthName: ""
  };
  isLoading: boolean = false;
  eventdetaildata: any;

  months = [
    'January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September',
    'October', 'November', 'December'
  ];

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    $(document).ready(() => {
      //open/close lateral filter
      $('.cd-filter-trigger').on('click', function () {
        triggerFilter(true);
      });
      $('.cd-filter .cd-close').on('click', function () {
        triggerFilter(false);
      });

      function triggerFilter($bool) {
        var elementsToTrigger = $([$('.cd-filter-trigger'), $('.cd-filter'), $('.cd-tab-filter'), $('.cd-gallery')]);
        elementsToTrigger.each(function () {
          $(this).toggleClass('filter-is-visible', $bool);
        });
      }
    });

    this.getseasoneventlist();
    this.getseasonslist();
    this.getstateslist();
    this.getmonth();
  }

  getseasonslist() {
    this.eventseasons = [];
    this.tourdateService.GetSeasonlist().subscribe(seasondata => {
      if (seasondata != null) {
        seasondata.forEach(childObj => {
          childObj.current = false;
          if (childObj.selected)
            childObj.current = true;

          this.eventseasons.push(childObj);
        });
        this.EventFilters.SeasonId = this.eventseasons.find(x => x.selected == true).value;
        this.EventFilters.SeasonName = this.eventseasons.find(x => x.selected == true).text;

        // this.getseasoneventlist();
      }
    }, err => {
      console.log('err in tour date component', err);
    });
  }

  getstateslist() {
    this.eventstates = [];
    this.eventstates.push({ 'text': 'ALL', 'value': 0 });

    this.tourdateService.GetStatelist().subscribe(statedata => {
      if (statedata != null) {
        statedata.forEach(childObj => {
          this.eventstates.push(childObj);
        });
        this.EventFilters.StateId = 0;
        this.EventFilters.StateName = 'ALL';
      }
    }, err => {
      console.log('err in tour date component', err);
    });
  }

  getmonth() {
    this.eventmonthrange.push({ 'text': 'ALL', 'value': 0 });
    this.eventmonthrange.push({ 'text': 'JANUARY', 'value': 1 });
    this.eventmonthrange.push({ 'text': 'FEBRUARY', 'value': 2 });
    this.eventmonthrange.push({ 'text': 'MARCH', 'value': 3 });
    this.eventmonthrange.push({ 'text': 'APRIL', 'value': 4 });
    this.eventmonthrange.push({ 'text': 'MAY', 'value': 5 });
    this.eventmonthrange.push({ 'text': 'JUNE', 'value': 6 });
    this.eventmonthrange.push({ 'text': 'JULY', 'value': 7 });
    this.eventmonthrange.push({ 'text': 'AUGUST', 'value': 8 });
    this.eventmonthrange.push({ 'text': 'SEPTEMBER', 'value': 9 });
    this.eventmonthrange.push({ 'text': 'OCTOBER', 'value': 10 });
    this.eventmonthrange.push({ 'text': 'NOVEMBER', 'value': 11 });
    this.eventmonthrange.push({ 'text': 'DECEMBER', 'value': 12 });

    this.EventFilters.Months = 0;
    this.EventFilters.MonthName = "ALL";
  }

  monthNumToName(monthnum) {
    return this.months[monthnum - 1] || '';
  }

  clickMonthFilter() {
    this.EventFilters.Months = 0;
    this.EventFilters.MonthName = "ALL";
    this.getseasoneventlist();
  }
  clickStateFilter() {
    this.EventFilters.StateId = 0;
    this.EventFilters.StateName = "ALL";
    this.getseasoneventlist();
  }

  clearEventlistData() {
    this.eventlistdata.allList = [];
    this.eventlistdata.conventionList = [];
    this.eventlistdata.nationalList = [];
    this.eventlistdata.regionalList = [];
  }

  getseasoneventlist() {
    this.tourDate = new TourDate();

    this.isLoading = true;

    this.clearEventlistData();

    if (this.EventFilters.SeasonId == null && this.EventFilters.SeasonId == 0) {
      this.EventFilters.SeasonId = 0;
    }

    this.tourDate.seasonId = this.EventFilters.SeasonId;
    this.tourDate.eventType = "ALL";
    this.tourDate.stateId = this.EventFilters.StateId;
    this.tourDate.month = this.EventFilters.Months;

    this.subscription = this.tourdateService.GetSeasonEventlist(this.tourDate).subscribe(responce => {
      this.isLoading = false;

      if (responce != null) {
        var res = responce.Response.Data;
        res.EventsList.AllList.forEach(childObj => {
          var monthItem = {
            month: childObj.month,
            monthEventList: childObj.monthEventList
          };
          this.eventlistdata.allList.push(monthItem);
        });
        res.EventsList.ConventionList.forEach(childObj => {
          var monthItem = {
            month: childObj.month,
            monthEventList: childObj.monthEventList
          };
          this.eventlistdata.conventionList.push(monthItem);
        });
        res.EventsList.RegionalList.forEach(childObj => {
          var monthItem = {
            month: childObj.month,
            monthEventList: childObj.monthEventList
          };
          this.eventlistdata.regionalList.push(monthItem);
        });
        res.EventsList.NationalList.forEach(childObj => {
          var monthItem = {
            month: childObj.month,
            monthEventList: childObj.monthEventList
          };
          this.eventlistdata.nationalList.push(monthItem);
        });
      }

    }, err => {
      console.log('err in tour date component', err);
      this.isLoading = false;
    });

  }

  // change functions

  clickSeason(_item) {

    this.eventseasons.forEach(childObj => {
      childObj.selected = false;
    });

    _item.selected = true;
    this.EventFilters.SeasonId = _item.value;
    this.EventFilters.SeasonName = _item.text;
    this.getseasoneventlist();
  }

  clickMonth(_item) {
    this.EventFilters.Months = _item.value;
    this.EventFilters.MonthName = _item.text;
    this.getseasoneventlist();
  }

  clickState(_item, event: Event) {
    let selectedstateText = event.target['options']
    [event.target['options'].selectedIndex].text;

    this.EventFilters.StateId = _item;
    this.EventFilters.StateName = selectedstateText;
    this.getseasoneventlist();
  }

  onEventdetailClick($event, eventseoname, isEventEnd) {
    $event.stopPropagation();

    if (isEventEnd == false) {
      doAnimate();
      setTimeout(() => {
        this.router.navigate(['/tour-dates', eventseoname]);
      }, 500);
    }
    else {
      doAnimate();
      setTimeout(() => {
        this.router.navigate(['/tour-dates', eventseoname]);
      }, 500);
    }
  }

  gotoRought(_url, _isHref) {
    doAnimate();
    setTimeout(() => {
      if (_isHref)
        window.open(_url, "_self");
      else {
        this.router.navigate([_url]);
      }
    }, 500);
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("back2Top").style.display = "block";
    } else {
      document.getElementById("back2Top").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 2000);
    return false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
