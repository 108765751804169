import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Http, Response } from "@angular/http";
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TourDateService } from '../../services/tour-date.service';
import { DomSanitizer } from '@angular/platform-browser';
import { doAnimate } from 'src/app/shared/app.helpers';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
//import { url } from 'inspector';

declare var $: any;

@Component({
  selector: 'app-tourdetail-postevent',
  templateUrl: './tourdetail-postevent.component.html',
  styleUrls: ['./tourdetail-postevent.component.scss'],
  providers: [TourDateService],
})
export class TourdetailPosteventComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  constructor(private tourdateService: TourDateService, private http: Http, private route: ActivatedRoute, private sanitizer: DomSanitizer, private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
  }

  eventseoname: any;
  eventdetaildata: any;
  videourllist: any = [];
  postEventDetail = {
    ResultUrl: ""
  };
  nationalfinalslist: any = [];
  isLoading: boolean = false;

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.route.paramMap.subscribe(params => {
      this.eventseoname = params.get('eventseoname');
    });

    this.getEventdetail(this.eventseoname);
    this.getNationalFinalsList();
  }

  getEventdetail(eventseoname) {
    this.isLoading = true;
    this.tourdateService.GetEventDetail(eventseoname).subscribe(evntdetail => {
      this.isLoading = false;
      if (evntdetail != null) {
        this.eventdetaildata = evntdetail;
        this.postEventDetail = this.eventdetaildata.Response.Data.EventDetail.PostEventDetail;
        this.eventdetaildata.Response.Data.EventDetail.PostEventDetail.VideoUrlList.forEach(childObj => {
          childObj = this.sanitizer.bypassSecurityTrustResourceUrl(childObj);
          this.videourllist.push(childObj);
        })
      }
    }, err => {
      console.log('err in tour date detail - Post event component', err);
      this.isLoading = false;
    });
  }

  getNationalFinalsList() {
    this.tourdateService.GetNationalFinalsList().subscribe(Nationalfinals => {
      if (Nationalfinals != null) {
        this.nationalfinalslist = Nationalfinals.Response.Data;
      }
    }, err => {
      console.log('err in tour date detail - Post event component', err);
    });
  }

  gotoRought(_url, _isHref) {
    doAnimate();
    setTimeout(() => {
      if (_isHref)
        window.open(_url, "_self");
      else {
        this.router.navigate([_url]);
      }
    }, 500);
  }

  getEventDetails(_url) {
    if (_url.indexOf(location.origin) > -1) {
      _url = _url.replace(location.origin, "");
    }
    this.gotoRought(_url, false);
  }

  onNavigate() {
    window.open(this.postEventDetail.ResultUrl);
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }
  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("back2Top").style.display = "block";
    } else {
      document.getElementById("back2Top").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 2000);
    return false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
