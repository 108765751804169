//let BASE_PATH = "http://localhost:4651/";
//let BASE_PATH = "http://192.169.154.20:98/";
let BASE_PATH = "https://www.groovecompetition.com/";

//let BASE_SERVER_PATH = "http://localhost:57997/";
//let BASE_SERVER_PATH = "http://stage.grooveregistration.com/";
let BASE_SERVER_PATH = "https://register.onebeatdance.com/";


export const API_URL = {
  //IMAGE_URL: 'https://www.grooveregistration.com/',

  FACULTY_URL: BASE_PATH + 'api/Faculty',
  FACULTY_DETAIL_URL: BASE_PATH + 'api/Facultydetail/',
  RULES_URL: BASE_PATH + 'api/RulesAndRegulations/',
  EXECUTIVETEAM_URL: BASE_PATH + 'api/ExecutiveTeam',
  LIVESTREAM_URL: BASE_PATH + 'api/LiveStream',
  DOWNLOADMEDIA_URL: BASE_PATH + 'api/DownloadMedia',
  TOURDATE_URL: BASE_PATH + 'api/TourDate',
  TOURDATE_SERVER_URL: BASE_SERVER_PATH + 'api/PublicSite/GetSeasonEventList',
  TOURDETAIL_SERVER_URL: BASE_SERVER_PATH + 'api/PublicSite/GetWebPage',
  NATIONALIMAGES_SERVER_URL: BASE_SERVER_PATH + 'api/PublicSite/GetNationalImageList',
  DOWNLOADMEDIA_EVENTLIST_SERVER_URL: BASE_SERVER_PATH + 'api/PublicSite/GetEventsList',
  DOWNLOADMEDIA_DANCERLIST_SERVER_URL: BASE_SERVER_PATH + 'api/PublicSite/GetDancersList',
  DOWNLOADMEDIA_ENTRYLIST_SERVER_URL: BASE_SERVER_PATH + 'api/PublicSite/GetEntriesList',
  DOWNLOADMEDIA_VERIFYEMAIL_SERVER_URL: BASE_SERVER_PATH + 'api/PublicSite/VerifyRegisterDancer',
  SPONSORGALLERY_SERVER_URL: BASE_SERVER_PATH + 'api/PublicSite/GetSponsorGalleryList',
  IMPORTANT_URL: BASE_SERVER_PATH + 'api/PublicSite/GetWebPage',
};
