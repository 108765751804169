import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { API_URL } from 'src/app/shared/constants/url.constants';
import { DownloadMedia} from '../components/download-media/download-media';
import { createRequestOption } from '../shared/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class DownloadMediaService {

  constructor(private http: HttpClient) { }

  ///** GET event media data from the server */
  Geteventmedia(): Observable<any> {
    return this.http.get(API_URL.TOURDATE_URL + "/GetSeasonlist?needToRemovePastSeasons=true");
    //return this.http.get(API_URL.DOWNLOADMEDIA_URL);
  }

  Getevents(seasonId): Observable<any> {
    const httpBody = new HttpParams()
      .set('SeasonId', seasonId)
      .set('IsFromReverb', 'false');

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    let options = { headers: headers };

    return this.http.post(API_URL.DOWNLOADMEDIA_EVENTLIST_SERVER_URL, httpBody, options);
    // return this.http.get(API_URL.DOWNLOADMEDIA_URL + "/" + seasonId);
  }

  Getdancers(eventid): Observable<any> {
    const httpBody = new HttpParams()
      .set('EventId', eventid);

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    let options = { headers: headers };

    return this.http.post(API_URL.DOWNLOADMEDIA_DANCERLIST_SERVER_URL, httpBody, options);
    //return this.http.get(API_URL.DOWNLOADMEDIA_URL + "/GetDancerList?eventid=" + eventid);
  }

  Getentries(eventid): Observable<any> {
    const httpBody = new HttpParams()
      .set('EventId', eventid);

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    let options = { headers: headers };

    return this.http.post(API_URL.DOWNLOADMEDIA_ENTRYLIST_SERVER_URL, httpBody, options);
    //return this.http.get(API_URL.DOWNLOADMEDIA_URL + "/GetEntryList?eventid=" + eventid);
  }

  Posteventmedia(downloadmedia?: DownloadMedia, req?: any): Observable<HttpResponse<any>> {
    const options = createRequestOption(downloadmedia);
    return this.http.post(API_URL.DOWNLOADMEDIA_URL, downloadmedia, {
      params: options,
      observe: 'response'
    });
  }

  VerifyDancerRegistration(verificationId): Observable<any> {
    const httpBody = new HttpParams()
      .set('verificationId', verificationId)
      .set('resend', 'false');

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    let options = { headers: headers };

    return this.http.post(API_URL.DOWNLOADMEDIA_VERIFYEMAIL_SERVER_URL, httpBody, options);
  }

  ResendVerification(downloadmedia?: DownloadMedia, req?: any): Observable<HttpResponse<any>> {
    const options = createRequestOption(downloadmedia);
    return this.http.put(API_URL.DOWNLOADMEDIA_URL, downloadmedia, {
      params: options,
      observe: 'response'
    });
  }
}
