import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { API_URL } from 'src/app/shared/constants/url.constants';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  ///** GET home data from the server */
  GetHomeData(): Observable<any> {
    const httpBody = new HttpParams()
      .set('SeoName', 'Home');

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    let options = { headers: headers };

    return this.http.post(API_URL.TOURDETAIL_SERVER_URL, httpBody, options);
  }

  ///** GET seasons from the server */
  GetSeasonlist(): Observable<any> {
    return this.http.get(API_URL.TOURDATE_URL + "/GetSeasonlist");
  }

  ///** GET home data from the server */
  GetLivestreamData(): Observable<any> {
    //return this.http.get(API_URL.LIVESTREAM_URL);
    const httpBody = new HttpParams()
      .set('SeoName', 'home-live');

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    let options = { headers: headers };

    return this.http.post(API_URL.TOURDETAIL_SERVER_URL, httpBody, options);
  }


  getSponsorGalleryList(): Observable<any> {
    const httpBody = new HttpParams();

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    let options = { headers: headers };

    return this.http.post(API_URL.SPONSORGALLERY_SERVER_URL, httpBody, options);
  }
}
