import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Http, Response } from "@angular/http";
import { BlogService } from '../../services/blog.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { debug } from 'util';

declare var $: any;

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss'],
  providers: [BlogService],
})
export class BlogDetailsComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  constructor(private blogService: BlogService, private http: Http, private route: ActivatedRoute, private router: Router, public sanitizer: DomSanitizer) { }
  blogdetail: any = [{
    Content: "",
    Title: "",
    Date: "",
    Description: ""
  }];
  blogseoname: any;
  blogsdate: any;
  isLoading: boolean = false;
  SafeURL: any;
  videourllist: any = [];

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.route.paramMap.subscribe(params => {
      this.blogseoname = params.get('seoname');
    });

    this.getBlog(this.blogseoname);
  }

  getBlog(blogseoname) {
    this.isLoading = true;
    this.subscription = this.blogService.GetBlogData(blogseoname).subscribe(data => {
      this.isLoading = false;
      if (data != null) {
        this.blogdetail.Content = data.Response.Data.Content;
        this.blogdetail.Title = data.Response.Data.Seo.Title;
        this.blogdetail.Description = data.Response.Data.Seo.Description;

        data.Response.Data.VideoFileUrl.forEach(childObj => {
          childObj = this.sanitizer.bypassSecurityTrustResourceUrl(childObj);
          this.videourllist.push(childObj);
        })
      }
    }, err => {
      console.log('err in news-detail component', err);
      this.isLoading = false;
    });
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    this.scrollFunction();
  }

  // When the user scrolls down 20px from the top of the document, show the button
  scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("back2Top").style.display = "block";
    } else {
      document.getElementById("back2Top").style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 2000);
    return false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
