import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { API_URL } from 'src/app/shared/constants/url.constants';

@Injectable({
  providedIn: 'root'
})

export class RulesandregulationsService {

  constructor(private http: HttpClient) { }

  ///** GET rules url from the server */
  GetRulesData(): Observable<any> {
    const httpBody = new HttpParams()
      .set('SeoName', 'rules-and-regulations');

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    let options = { headers: headers };

    return this.http.post(API_URL.TOURDETAIL_SERVER_URL, httpBody, options);
  }
}
